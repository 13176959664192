export const socialsData = {
    github: '',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/aydinkubra/',
    instagram: 'https://www.instagram.com/kubraserbetciaydin/',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: ''
}